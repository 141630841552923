<template>
  <div>
    <div
      v-if="!loading"
      style="
        height: 100vh;
        contain: content;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <div
        style="
          height: 36px;
          width: 100%;
          display: flex;
          align-items: center;
          background-color: var(--v-secondary-base) !important;
          background: var(--v-secondary-base) !important;
        "
      >
        <v-btn plain icon class="mx-2" @click="$router.go(-1)"
          ><v-icon>arrow_back</v-icon></v-btn
        >
        <span style="font-size: 14px" class="ml-2"
          ><b>
            {{ $Format.capitalizeFirstLetter(query.type) }}:
            {{ query.reference }}
          </b></span
        >
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="shipment && shipment.shippingLine">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  outlined
                  style="border: none"
                  class="mx-1"
                  @click="editVoyage()"
                >
                  <v-avatar
                    left
                    color="white"
                    size="48"
                    v-if="shipment.shippingLine"
                  >
                    <v-img
                      v-if="shipment.shippingLine && shipment.shippingLine.logo"
                      :src="shipment.shippingLine.logo"
                      contain
                    ></v-img>
                  </v-avatar>
                  <v-icon left v-else :color="'grey'">directions_boat</v-icon>
                  <span v-if="shipment.shippingLine"
                    ><span v-if="shipment.shippingLine.friendlyName"
                      >{{ shipment.shippingLine.friendlyName }}:
                    </span>
                    <span v-else>{{ shipment.shippingLine.name }}: </span>
                  </span>
                  <span v-if="shipment.vessel" class="ml-1"
                    >{{ shipment.vessel.name }} {{ shipment.voyage }}</span
                  >
                  <span v-else>No Vessel</span>
                  <!-- <span v-if="shipment.vessel.imoNumber" class="ml-1" style="color: grey">{{ shipment.vessel.imoNumber }}</span> -->
                </v-chip>
              </template>
              <span>Vessel</span>
            </v-tooltip>
        <v-chip outlined style="border: none" class="ml-2" :key="routeKey">
          <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-chip pill small outlined class="primaryText--text">
                  <v-avatar size="24" left>
                    <v-img
                      contain
                      v-if="location.locode"
                      :src="`https://cdn.loglive.io/flags/4x3/${location.locode
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img> </v-avatar
                  >{{ location.locode }}
                </v-chip>

                <v-icon class="mx-3" small v-if="index < fullRoute.length - 1"
                  >east</v-icon
                >
              </div>
            </template>
            <span style="font-size: 12px"
              >{{ location.type }}: {{ location.locode }}</span
            >
          </v-tooltip>
        </v-chip>
      </div>
      <div class="contents">
        <div class="mainContentWrap">
          <v-tabs height="30px" v-model="currentTab" class="tabby" style="">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#${tab.value}`"
            >
              <v-icon left small>{{ tab.icon }}</v-icon>
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <div class="mainContent">
            <v-tabs-items
              v-model="currentTab"
              style="height: inherit; width: 100%"
            >
              <v-tab-item style="height: inherit; width: 100%" value="file">
                <File :shipment="shipment" />
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="uploads">
                <Uploads :shipment="shipment" />
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="container">
                <Container :containerId="query.id" :container="container" :shipment="shipment" />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      style="height: 90vh; padding-top: 0; margin-top: 0"
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>

<script>
import Container from './components/Container.vue'
// import Booking from './components/Booking.vue'
import File from "./components/File.vue";
import moment from "moment"
import Uploads from "../Documentation/Uploads.vue";

export default {
  components: {
    Container,
    // Booking,
    File,
    Uploads,
  },
    data: () => ({
      allTabs: [
      { name: "Detail", value: "container", icon: "widgets", type: ["CONTAINER"] },
      { name: "File", value: "file", icon: "description", type: ["FILE"] },
      { name: "Uploads", value: "uploads", icon: "upload_file", type: ["FILE"] }
    ],
    container: null,
    currentTab: "file",
    fullRoute: [],
    query: {
      type: null,
      id: null,
      reference: null,
    },
    recources: {
      shipmentId: null,
      containerId: null,
      bookingId: null,
    },
    loading: true,
    routeKey: 100,
    shipment: null,
    tabs: [],
  }),
  watch: {
    "$route.query.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.query.id = val;
          this.query.type = this.$route.query.type;
          this.query.reference = this.$route.query.reference;
          this.load();
        }
      },
    },
  },
  methods: {
    calculateDays(start, end) {
      start = moment(start);
      end = moment(end);
      let difference = end.diff(start, "days");
      let count = difference;
      return count + 1;
    },
      load() {
      this.tabs = this.allTabs.filter(x => x.type.includes(this.query.type))
        this.currentTab = this.tabs[0].value
      switch (this.query.type) {
        case "FILE":
          this.loadFile(this.query.id);
          break;
        case "BOOKING":
          this.loadBooking(this.query.id);
          break;
        case "CONTAINER":
          this.loadContainer(this.query.id);
          break;
      }
    },
    getFullRoute() {
      let result = [];
      let setTypes = [
        { type: "Port of Load", locode: this.shipment.portOfLoadValue },
        {
          type: "Port of Discharge",
          locode: this.shipment.portOfDischargeValue,
        },
        {
          type: "Final Destination",
          locode: this.shipment.finalDestinationValue,
        },
      ];
      result.push(setTypes[0]);
      if (
        this.shipment.sailingSchedule &&
        this.shipment.sailingSchedule.sailingScheduleLegs.length > 1
      ) {
        const findLeg = this.shipment.sailingSchedule.sailingScheduleLegs.find(
          (x) => x.leg === 1
        );
        if (findLeg && findLeg.portOfDischargeValue) {
          result.push({
            type: "TS Port 1",
            locode: findLeg.portOfDischargeValue,
          });
        }
      }
      if (
        this.shipment.portOfDischargeValue ===
        this.shipment.finalDestinationValue
      ) {
        result.push({
          type: "POD & Final Destination",
          locode: this.shipment.finalDestinationValue,
        });
      } else {
        result.push(setTypes[1]);
        result.push(setTypes[2]);
      }
      this.fullRoute = result;
      return result;
    },
    async loadFile(id) {
      this.loading = true;
      let result = await this.$API.getPublicShipmentDetail(id);
      this.shipment = result;
      if (new Date(this.shipment.etd) < new Date()) {
            let difference = this.calculateDays(
              new Date(this.shipment.etd),
              new Date()
            );
            let shipmentTotal = this.calculateDays(
              new Date(this.shipment.etd),
              new Date(this.shipment.eta)
            );
            difference =
              difference > shipmentTotal ? shipmentTotal : difference;
            this.shipment.voyageProgress = difference > 0 ? difference : 0;
          } else {
            this.shipment.voyageProgress = 0;
          }
      this.getFullRoute();
      this.loading = false;
    },
    async loadBooking(id) {
      let result = await this.$API.getBooking(id);
      console.log(result);
    },
    async loadContainer(id) {
      this.loading = true;
      let result = await this.$API.getContainer(id);
      this.container = result;
      if (this.container.shipmentId) {
        this.tabs = this.allTabs.filter(x => x.type.includes(this.query.type) || x.type.includes("FILE"))
        this.loadFile(this.container.shipment.shipmentReference)
        this.container.productSummary = await this.$API.getContainerSummary(
          this.container.shipmentId,
          this.container.id
        );
      } else {
        this.container.productSummary = []
      }
    //   this.getFullRoute();
      this.loading = false;
    },
  },
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}

.topBar {
  height: var(--toolBarHeight);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--v-greyDarker-base) !important;
  background: var(--v-greyDarker-base) !important;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
}
.glass {
  background-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}
</style>